.specification {
    height: 100%;
    width: 100%;
    top: 100%;
    position: absolute;
    transition: all .4s ease-out;
    background: #062C26;
    display: flex;
    justify-content: center;
    align-items: center;
}

.specificationContent {
    position: absolute;
    width: 73%;
    height: 82%;
    left: 345px;
    top: 130px;
    background: url("/public/specification/spec.png");
    background-size:contain;
    background-repeat: no-repeat;
}

@media (max-height: 780px) and (min-width:1370px) {
    .specificationContent {
        width: 70%;
        height: 65%;
    }
}

@media (max-width: 1300px) {
    .specificationContent { 
        width: 75%;
        left: 235px;
    }
}

@media (max-width: 1060px) {
    .specificationContent {
        width: calc(75% - 80px);
    }
}

@media (max-width: 920px) {
    .specificationContent {
        width: calc(73% - 90px);
    }
}