.availability {
    height: 100%;
    width: 100%;
    position: absolute;
    transition: all .4s ease-out;
    background: #2C0E3F;
    top:100%
}

.availHeader {
    position: absolute;
    height: 26px;
    left: 340px;
    top: 150px;

    /* Body Copy */

    font-family: Sharp Grotesk Book19;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    /* or 36px */

    letter-spacing: 0.02em;

    /* Light Blue */

    color: #008ABC;
}

.fullModal {
    top:30px;
    position: absolute;
    width: 58%;
    right: 30px;
    bottom: 30px;
    text-align: right;
}

.availFullImg{
    height:100%;
}

.availFullImg,.availOverLayImg {
    position: relative;
    top: 0;
    height:100%;
    object-fit: contain;
}

.availOverLayImg {
    opacity: 0.6;
}

@media (max-height: 900px) {
    .availHeader {
        font-size: 22px;
    }
} 

@media (max-height: 800px) {
    .availHeader {
        top: 20%;
    }
}

@media (max-width: 1450px) {
    .fullModal {
        width: 50%;
    }
}

@media (max-width: 1300px) {
    .availHeader {
        left: 226px;
    }
}

@media (max-width: 880px) {
    .availHeader {
        top: 85px;
    }
    .fullModal {
        width: 40%;
    }
}