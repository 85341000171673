.galleryComp {
    height: 100%;
    width: 100%;
    position: absolute;
    transition: all .4s ease-out;
    top:100%;
    display: flex;
    background: #360F29;
}

.galleryScroll {
    width: 70%;
    height: 80vh;
    margin-left: 340px;
    top: 100%;
    margin-top: 7%;
    display: flex;
    justify-content: center;
}

.slider-preview1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper {
    height: 100%;
    width: 100%;
}
  
  .swiper-slide {
    height: 100%;
    width: 100%;
    margin: auto auto;
    display:flex;
    justify-content:center;
}

.galleryImg1,.galleryImg2,.galleryImg3,.galleryImg4,.galleryImg5,.galleryImg6,.galleryImg7,.galleryImg8,.galleryImg9,.galleryImg10,.galleryImg11,.galleryImg12,.galleryImg13,.galleryImg14,.galleryImg15,.galleryImg16{
    width: 95%;
    max-width: 95%;
    height: 100%;
    transition: all .4s ease-out;
    top: 100%;
    background: url("../../../public/Gallery/1.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    
}

 .galleryImg2 {
    background: url("../../../public/Gallery/2.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg3 {
    
    background: url("../../../public/Gallery/3.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg4 {
    
    background: url("../../../public/Gallery/4.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg5 {
    
    background: url("../../../public/Gallery/5.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg6 {
    
    background: url("../../../public/Gallery/6.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg7 {
    
    background: url("../../../public/Gallery/7.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg8 {
    
    background: url("../../../public/Gallery/8.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg9 {
    
    background: url("../../../public/Gallery/9.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg10 {
    background: url("../../../public/Gallery/10.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg11 {
    background: url("../../../public/Gallery/11.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg12 {
    background: url("../../../public/Gallery/12.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg13 {
    background: url("../../../public/Gallery/13.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg14 {
    background: url("../../../public/Gallery/14.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg15 {
    background: url("../../../public/Gallery/15.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.galleryImg16 {
    background: url("../../../public/Gallery/16.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}


.galleryVideo1 {
    width: 90%;
    height: 100%;
    padding: 0px 20px;
    transition: all .4s ease-out;
    top: 100%;
}

.viewFullScreenGallery {
    width: 128px;
    top: 100%;
    cursor: default;
    transition: all .4s ease-out;
    /* Caption Text */

    font-family: Sharp Grotesk Book;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */

    display: flex;
    align-items: center;
    text-align: center;

    /* Grey */

    color: #B2B2B2;
}

.viewFullGallery {
    color: #FF7B4A;
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
    transition: all .4s ease-out;
    font-family: Sharp Grotesk Book;
    text-decoration: underline;
    top: 100%;
}


.close svg path+path{
    fill:#fff;
}

.swiper-pagination-bullet{
    opacity: 0;
}
  

.swiper-pagination-bullet.swiper-pagination-bullet-active{
    opacity:0;
}

.swiper-button-next::after, .swiper-button-prev::after {
    opacity: 0;
}

@media (max-width:1350px) {
    .galleryScroll{
        margin-top: 9%;
        /* width: calc(100% - 360px); */
    }
    .galleryImg1,.galleryImg2,.galleryImg3,.galleryImg4,.galleryImg5,.galleryImg6,.galleryImg7,.galleryImg8,.galleryImg9,.galleryImg10,.galleryImg11,.galleryImg12,.galleryImg13,.galleryImg14,.galleryImg15,.galleryImg16{
        object-fit: contain;
        width: 100%;
    }
}

@media (max-width:1300px) {
    .galleryScroll{
        margin-left: 235px;
        width: calc(100% - 255px);
    }
}

@media (max-width:1100px) {
    .galleryScroll{
        margin-top: 13%;
        
    }
}

@media (max-width:950px) {
    .galleryScroll{
        margin-top: 15%;
        width: calc(100% - 255px);
    }
}
