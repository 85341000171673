.newPlans {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 100%;
    transition: all .4s ease-out;
    background: #001E60;
}

.introPlanImage {
    position: absolute;
    width: 80%;
    height: 90%;
    left: 22%;
    top: 10%;
    background: url("/public/plans/planIntro.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.fifthFloor {
    position: absolute;
    width: 205px;
    height: 16px;
    left: 70px;
    top: 272px;
    font-family:Sharp Grotesk Book;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
    /* or 14px */
    
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 1;
    color: rgba(47, 178, 146, 0.5); 
}

.TransportPlanImg,.AmenitiesPlanImg,.fifthFloorPlanImg,.fourthFloorPlanImg,.thirdFloorPlanImg,.secondFloorPlanImg,.firstFloorPlanImg,.groundFloorPlanImg,.lgFloorPlanImg {
    position: absolute;
    width: 73%;
    height: 82%;
    left: 345px;
    top: 130px;

    background: url("/public/BrainYard-Plans/5.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.TransportPlanImg {
    background: url("/public/BrainYard-Plans/transport.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.AmenitiesPlanImg {
    background: url("/public/BrainYard-Plans/amenities.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.fourthFloorPlanImg {
    background: url("/public/BrainYard-Plans/4.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.thirdFloorPlanImg {
    background: url("/public/BrainYard-Plans/3.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.secondFloorPlanImg {
    background: url("/public/BrainYard-Plans/2.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.firstFloorPlanImg {
    background: url("/public/BrainYard-Plans/1.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.groundFloorPlanImg {
    background: url("/public/BrainYard-Plans/g.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.lgFloorPlanImg {
    background: url("/public/BrainYard-Plans/lg.png");
    background-size:contain;
    background-repeat: no-repeat;
}

.navbar{
    margin-top: 60px;
    right: 20px; 
    display: flex;
    width: fit-content;
    position: absolute;
}

.viewFullScreenPlan {
    padding: 0 20px;
    cursor: default;
    /* Caption Text */
    z-index: 11;
    font-family: Sharp Grotesk Book;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */
    
    display: flex;
    align-items: center;
    text-align: center;

    /* Grey */

    color: #B2B2B2;
}

.linkFullScreenPlan {
    color: #2FB292;
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
    font-family: Sharp Grotesk Book;
    text-decoration: underline;
}

.plansOptions {
    font-size: 10px;
    margin: -5px 0px;
}

@media (max-width: 1280px) {
  
    .fifthFloor {
        font-size: 10px;
        
    }
} 

@media (max-height: 600px) {
  
    .fifthFloor {
        font-size: 8px;
    }
} 

@media (max-width: 1250px) {
    .introPlanImage {
        top: 13%;
    }
} 

@media (max-width: 1000px) {
    .introPlanImage {
        top: 19%;
        left: 22%;
    }
}


@media (max-width: 780px) {
    .introPlanImage {
        top: 10%;
        left: 200px;
        width: calc(100% - 200px);
    }
} 

@media (max-height: 780px) and (min-width:1370px) {
    .TransportPlanImg,.AmenitiesPlanImg,.fifthFloorPlanImg,.fourthFloorPlanImg,.thirdFloorPlanImg,.secondFloorPlanImg,.firstFloorPlanImg,.groundFloorPlanImg,.lgFloorPlanImg {
        width: 70%;
        height: 65%;
    }
}

@media (max-height: 750px) {
    .fifthFloor {
        font-size: 9px;
        line-height: -30%;
    }
}   


@media (max-width: 1300px) {
    .TransportPlanImg,.AmenitiesPlanImg,.fifthFloorPlanImg,.fourthFloorPlanImg,.thirdFloorPlanImg,.secondFloorPlanImg,.firstFloorPlanImg,.groundFloorPlanImg,.lgFloorPlanImg {
        width: 75%;
        left: 235px;
    }
}

@media (max-width: 1060px) {
    .TransportPlanImg,.AmenitiesPlanImg,.fifthFloorPlanImg,.fourthFloorPlanImg,.thirdFloorPlanImg,.secondFloorPlanImg,.firstFloorPlanImg,.groundFloorPlanImg,.lgFloorPlanImg {
        width: calc(75% - 80px);
    }
}

@media (max-width: 920px) {
    .TransportPlanImg,.AmenitiesPlanImg,.fifthFloorPlanImg,.fourthFloorPlanImg,.thirdFloorPlanImg,.secondFloorPlanImg,.firstFloorPlanImg,.groundFloorPlanImg,.lgFloorPlanImg {
        width: calc(73% - 90px);
    }
}

@media (max-height: 500px) {
    .plansOptions {
        font-size: 7.5px;
        margin: -2px 0px;
    }
} 