.sideBar {
    height: 100%;
}

.buildingText,.plansText,.galleryText,.availabilityText,.specText {
    position: absolute;
    width: 205px;
    height: 16px;
    left: 70px;
    top: 40px;

    /* Caption Text */

    font-family: Sharp Grotesk Book;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */

    display: flex;
    align-items: center;

    cursor: pointer;
    z-index: 2;
    color: #2B4B47
}

.outerDot,.outerDotFinal {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 30px;
    border-radius: 50px;
    z-index: 2;
    /* Orange */

    background: #B2B2B2;
}


.innerDot {
    position: relative;
    left: 8px;
    top: 8px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    /* Dark Green */
    background: #062C26;
}

.initialTube {
    box-sizing: border-box;

    position: absolute;
    width: 8px;
    height: 90%;
    left: 38px;
    top: 5%;

    /* Grey */

    border: 1px solid #B2B2B2;
}

.leftlineBuilding {
    position: absolute;
    
    width: 4px;
    height: 22%;
    left: 38px;
    top: 1047px;
    visibility: hidden;
    /* Grey */
    transition: all .4s ease-in-out;
    background: #B2B2B2;
    
}

.rightlineBuilding {
    position: absolute;
    width: 4px;
    height: 22%;
    left: 42px;
    top: 900px;
    visibility: hidden;
    /* Orange */
    transition: all .4s ease-in-out;
    
    background: #FF7B4A;
}


.leftlinePlans {
    position: absolute;
    width: 4px;
    height: 22%;
    left: 38px;
    top: 1100px;
    visibility: hidden;

    /* Turquoise */
    transition: all .4s ease-in-out;
    z-index: 1;
    background: #2FB292;
}

.rightlinePlans {
    position: absolute;
    width: 4px;
    height: 22%;
    left: 42px;
    top: 1100px;
    visibility: hidden;

    /* Grey */
    transition: all .4s ease-in-out;
    z-index: 1;
    background: #B2B2B2;
}

.leftlineGallery {
    position: absolute;
    width: 4px;
    height: 22.5%;
    left: 38px;
    top: 1300px;
    visibility: hidden;
    /* Grey */
    transition: all .4s ease-in-out;
    z-index: 1;
    background: #B2B2B2;
}

.rightlineGallery {
    position: absolute;
    width: 4px;
    height: 22.5%;
    left: 42px;
    top: 1300px;
    visibility: hidden;
    /* Pink */
    transition: all .4s ease-in-out;
    z-index: 1;
    background: #EF455B;
}

.leftlineAvail {
    position: absolute;
    width: 4px;
    height: 24%;
    left: 38px;
    top: 1500px;

    /* Light Blue */

    visibility: hidden;
    transition: all .4s ease-in-out;
    z-index: 1;
    background: #008ABC;
}

.rightlineAvail {
    position: absolute;
    width: 4px;
    height: 24%;
    left: 42px;
    top: 1500px;

    visibility: hidden;
    transition: all .4s ease-in-out;
    /* Grey */
    z-index: 1;
    background: #B2B2B2;
}

@media (max-width: 1180px) {
    .buildingText,.plansText,.galleryText,.availabilityText,.specText  {
        font-size: 12px;
    }
} 