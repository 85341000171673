.building {
    height: 100%;
    width: 100%;
    bottom: 100%;
    position: absolute;
    transition: all .4s ease-out;
    background: #062C26;
}

.buildingImgBox {
    position: absolute;
    width: 85%;
    left: 15%;
    height: 100vh;
}

.buildingModalImg,.buildingModalImg1  {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5%;
    left: 15%;
    width: 900px;
    height: 900px;
}

.buildingModalImg1 {
    top: 10%;
}

.actual-img {

    background-size:contain;
    background-repeat: no-repeat;
}

.buildingCompContent {
    position: relative;
    left: 20%;
    width: 80%;
    height: 100vh;
}

@media (max-width: 1300px) {
    .buildingCompContent {
        left: 200px;
        width: calc(100% - 200px);
    }
}

@media (min-width: 1400px) {
    .buildingCompContent {
        left:280px;
        width: calc(100% - 280px);
    }
}

@media (max-width: 1250px) {
  
    .buildingModalImg,.buildingModalImg1 {
        top: 10%;
        width: 750px;
        height: 750px;
    }

    .buildingModalImg1 {
        top: 20%;
    }
} 

@media (max-height: 800px) {
  
    .buildingModalImg,.buildingModalImg1 {
        top: 10%;
        
        width: 750px;
        height: 750px;
    }

    .buildingModalImg1 {
        top: 20%;
    }
} 

@media (max-height: 720px),(max-width:1190px) {
  
    .buildingModalImg,.buildingModalImg1 {
        top: 10%;
        left: 15%;
        width: 700px;
        height: 700px;
    }

    .buildingModalImg1 {
        top: 20%;
    }
} 

@media (max-height: 670px),(max-width:930px) {
  
    .buildingModalImg,.buildingModalImg1 {
        top: 10%;
        left: 18%;
        width: 600px;
        height: 600px;
    }

    .buildingModalImg1 {
        top: 20%;
    }
} 

@media (max-height: 600px),(max-width:815px) {
  
    .buildingModalImg,.buildingModalImg1 {
        top: 10%;
        left: 22%;
        width: 500px;
        height: 500px;
    }

    .buildingModalImg1 {
        top: 20%;
    }
} 

@media (min-height: 760px) and (max-width:930px) {
  
    .buildingModalImg,.buildingModalImg1 {
        top: 20%;
        left: 18%;
        width: 600px;
        height: 600px;
    }

    .buildingModalImg1 {
        top: 20%;
    }
}

@media (max-width: 880px) {
    .buildingImgBox {
        width: calc(100% - 200px);
        left: 200px;
    }

    .buildingModalImg, .buildingModalImg1{
        left: 0;
        top: 15%;
        width: 100%;
        height: auto;
    }
}
